<template>
	<section
		class="account-overview dashboard"
		v-if="
			this.$store.state.getUserData &&
				this.$store.state.getUserData.role === 'Admin'
		"
	>
		<div class="inner-wrapper">
			<div class="container">
				<div class="column-wrapper fullwidth">
					<!-- <div class="column nav">
						<nav class="account-nav" id="account-nav">
							<ul>
								<li>
									<router-link to="/dashboard">Dashboard</router-link>
								</li>
							</ul>
						</nav>
					</div> -->
					<div class="column main-account">
						<div class="function-row no-form-just-btn">
							<h2 class="title-tag">Media sync</h2>
							<div v-if="showMediaError" class="error-msg">
								<p>{{ errorMediaMsg }}</p>
							</div>
							<div v-if="showMediaSucces" class="succes-msg">
								<p>{{ succesMediaMsg }}</p>
							</div>
							<div class="special-button-wrapper">
								<button
									:disabled="disableMediaSyncBtn"
									class="button"
									@click="startMediaSync"
								>
									Start media sync
								</button>
							</div>
						</div>
						<div class="function-row no-form-just-btn">
							<h2 class="title-tag">Content sync</h2>
							<div v-if="showContentError" class="error-msg">
								<p>{{ errorContentMsg }}</p>
							</div>
							<div v-if="showContentSucces" class="succes-msg">
								<p>{{ succesContentMsg }}</p>
							</div>
							<div class="special-button-wrapper">
								<button
									:disabled="disableContentSyncBtn"
									class="button"
									@click="startContentSync"
								>
									Start content sync
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
// @import constants
import { admin_content_sync, admin_media_sync } from "@/constants/endpoints";

import { axiosPost, getUserToken } from "@streampac.io/chef_sp_1";

export default {
	name: "Account",
	inject: ["isAuthenticated"],
	data() {
		return {
			currentUser: null,
			savedToken: "",
			showContentError: false,
			errorContentMsg: "",
			showContentSucces: false,
			succesContentMsg: "",
			disableContentSyncBtn: false,
			showMediaError: false,
			errorMediaMsg: "",
			showMediaSucces: false,
			succesMediaMsg: "",
			disableMediaSyncBtn: false,
		};
	},
	components: {},
	created() {
		if (this.isAuthenticated) {
			//Temporary check for Admin user
			this.checkAdmin();
		}
		if (this.isAuthenticated) {
			getUserToken((token) => {
				this.savedToken = token;
			});
		}
	},
	methods: {
		checkAdmin() {
			setTimeout(() => {
				if (
					!this.$store.state.getUserData ||
					(this.$store.state.getUserData &&
						this.$store.state.getUserData.role !== "Admin")
				) {
					this.$router.replace({ name: "Home" });
				}
			}, 500);
		},
		startContentSync() {
			axiosPost(admin_content_sync, "", this.savedToken, (response) => {
				this.disableContentSyncBtn = true;
				//console.log(response);
				if (response.succes) {
					this.showContentSucces = true;
					this.showContentError = false;
					this.succesContentMsg = "De content sync is gelukt!";

					setTimeout(() => {
						this.showContentError = false;
						this.errorContentMsg = "";
						this.showContentSucces = false;
						this.succesContentMsg = "";
						this.disableContentSyncBtn = false;
					}, 2000);
				} else {
					this.showContentError = true;
					this.errorContentMsg = "Er is iets mis gegaan..";
					this.disableContentSyncBtn = false;
				}
			});
		},
		startMediaSync() {
			axiosPost(admin_media_sync, "", this.savedToken, (response) => {
				this.disableMediaSyncBtn = true;
				if (response.succes) {
					this.showMediaSucces = true;
					this.showMediaError = false;
					this.succesMediaMsg = "De media sync is gelukt!";

					setTimeout(() => {
						this.showMediaError = false;
						this.errorMediaMsg = "";
						this.showMediaSucces = false;
						this.succesMediaMsg = "";
						this.disableMediaSyncBtn = false;
					}, 2000);
				} else {
					this.showMediaError = true;
					this.errorMediaMsg = "Er is iets mis gegaan..";
					this.disableMediaSyncBtn = false;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
// @import styles
// @import './index.scss';
</style>
